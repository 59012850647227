import React, { ReactNode } from 'react';

import { getEnvVar } from '#shared/envs';

export const injectAxeptio: () => ReactNode = () =>
  getEnvVar('VITE_AXEPTIO_ID') &&
  getEnvVar('VITE_AXEPTIO_DOMAIN') &&
  getEnvVar('VITE_AXEPTIO_VERSION') && (
    <script
      src="https://static.axept.io/sdk.js"
      type="text/javascript"
      async
      data-id={getEnvVar('VITE_AXEPTIO_ID')}
      data-cookies-domain={getEnvVar('VITE_AXEPTIO_DOMAIN')}
      data-cookies-version={getEnvVar('VITE_AXEPTIO_VERSION')}
    />
  );
