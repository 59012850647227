import { Env, EnvFeaturesType, EnvVars, EnvVarsType } from '#shared/typings/envs';
import { FeatureName } from '#shared/typings/secure';
import { Nullable } from '#shared/typings/types';

export function isProduction(): boolean {
  // eslint-disable-next-line no-process-env
  return process.env.NODE_ENV === 'production';
}

export const getEnv = (): Env => {
  if (
    // eslint-disable-next-line no-process-env
    process.env.NODE_ENV === 'development'
  ) {
    return 'dev';
  } else if (
    // eslint-disable-next-line no-process-env
    process.env.NODE_ENV === 'test'
  ) {
    return 'test';
  } else if (window.location.host.includes('feature')) {
    return 'feature';
  } else if (window.location.host.includes('preproduction')) {
    return 'preproduction';
  } else if (window.location.host.includes('demo')) {
    return 'demo';
  } else {
    return 'prod';
  }
};

export const partners: Record<string, PartnerConfiguration> = {
  legalstart: {
    slug: 'legalstart',
    stylesheet: '/brands/legalstart/theme.css',
    logo: '/brands/legalstart/logo.svg',
    i18nContext: 'partner',
    landing: false,
  },
  qonto: {
    slug: 'qonto',
    stylesheet: '/brands/qonto/theme.css',
    logo: '/brands/qonto/logo.svg',
    i18nContext: 'partner',
    landing: true,
  },
};

export type PartnerConfiguration = {
  slug: string;
  stylesheet: string;
  logo: string;
  i18nContext: string;
  landing: boolean;
};

export const getPartner = (): Nullable<PartnerConfiguration> => {
  const domainProps = window.location.hostname.split('.');
  const partner = domainProps.shift() ?? '';

  if (!['app', 'extranet', 'quote', 'auth', 'www'].includes(partner) && partners[partner] !== undefined) {
    return partners[partner];
  }

  return null;
};

export const getMainDomain = (): string => {
  const domain: string = window.location.host;
  const domainProps = domain.split('.');

  if (domainProps.shift() === getPartner()?.slug) {
    domainProps.shift();
  }

  return domainProps.join('.');
};

const getOverloadedEnvVar = (): Record<EnvVars, string | undefined> => {
  const envVar = {
    VITE_ENV: import.meta.env.VITE_ENV,
    VITE_API_ENDPOINT: import.meta.env.VITE_API_ENDPOINT,
    VITE_STATIC_ENDPOINT: import.meta.env.VITE_STATIC_ENDPOINT,
    VITE_APP_HOST: import.meta.env.VITE_APP_HOST,
    VITE_AUTH_HOST: import.meta.env.VITE_AUTH_HOST,
    VITE_EXTRANET_HOST: import.meta.env.VITE_EXTRANET_HOST,
    VITE_QUOTE_HOST: import.meta.env.VITE_QUOTE_HOST,
    VITE_SENTRY_DSN_APP: import.meta.env.VITE_SENTRY_DSN_APP,
    VITE_SENTRY_DSN_AUTH: import.meta.env.VITE_SENTRY_DSN_AUTH,
    VITE_SENTRY_DSN_QUOTE: import.meta.env.VITE_SENTRY_DSN_QUOTE,
    VITE_SENTRY_DSN_EXTRANET: import.meta.env.VITE_SENTRY_DSN_EXTRANET,
    VITE_COOKIE_DOMAIN: import.meta.env.VITE_COOKIE_DOMAIN,
    VITE_APP_HUB_PATH: import.meta.env.VITE_APP_HUB_PATH,
    VITE_APP_MATCHING_PATH: import.meta.env.VITE_APP_MATCHING_PATH,
    VITE_APP_REVENUE_LOSS_PATH: import.meta.env.VITE_APP_REVENUE_LOSS_PATH,
    VITE_APP_HEALTHCARE_PATH: import.meta.env.VITE_APP_HEALTHCARE_PATH,
    VITE_APP_COACH_PATH: import.meta.env.VITE_APP_COACH_PATH,
    VITE_API_PATH: import.meta.env.VITE_API_PATH,
    VITE_BASE_NAME: import.meta.env.VITE_BASE_NAME,
    VITE_SWITCH_USER_PATH: import.meta.env.VITE_SWITCH_USER_PATH,
    VITE_AS_CUSTOMER_PATH: import.meta.env.VITE_AS_CUSTOMER_PATH,
    VITE_DO_NOT_TRACK_PATH: import.meta.env.VITE_DO_NOT_TRACK_PATH,
    VITE_ITEMS_PER_PAGE: import.meta.env.VITE_ITEMS_PER_PAGE,
    VITE_MSB_DASHBOARD_LETTER_ENDPOINT: import.meta.env.VITE_MSB_DASHBOARD_LETTER_ENDPOINT,
    VITE_HUBSPOT_ENDPOINT: import.meta.env.VITE_HUBSPOT_ENDPOINT,
    VITE_TITLE_APP: import.meta.env.VITE_TITLE_APP,
    VITE_TITLE_AUTH: import.meta.env.VITE_TITLE_AUTH,
    VITE_TITLE_QUOTE: import.meta.env.VITE_TITLE_QUOTE,
    VITE_TITLE_EXTRANET: import.meta.env.VITE_TITLE_EXTRANET,
    VITE_DESCRIPTION: import.meta.env.VITE_DESCRIPTION,
    VITE_GTM_APP: import.meta.env.VITE_GTM_APP,
    VITE_GTM_EXTRANET: import.meta.env.VITE_GTM_EXTRANET,
    VITE_AXEPTIO_ID: import.meta.env.VITE_AXEPTIO_ID,
    VITE_AXEPTIO_DOMAIN: import.meta.env.VITE_AXEPTIO_DOMAIN,
    VITE_AXEPTIO_VERSION: import.meta.env.VITE_AXEPTIO_VERSION,
    VITE_HUBSPOT_PORTAL: import.meta.env.VITE_HUBSPOT_PORTAL,
    VITE_HUBSPOT_MEETING_PATH: import.meta.env.VITE_HUBSPOT_MEETING_PATH,
    VITE_TERMS_SERVICE_PATH: import.meta.env.VITE_TERMS_SERVICE_PATH,
    VITE_PRIVACY_POLICE_PATH: import.meta.env.VITE_PRIVACY_POLICE_PATH,
    VITE_LEGAL_NOTICE_PATH: import.meta.env.VITE_LEGAL_NOTICE_PATH,
    VITE_COMPLAINTS_PATH: import.meta.env.VITE_COMPLAINTS_PATH,
    VITE_FAQ_PATH: import.meta.env.VITE_FAQ_PATH,
    VITE_STRIPE_API_KEY: import.meta.env.VITE_STRIPE_API_KEY,
    VITE_LOADER_STEP_DURATION: import.meta.env.VITE_LOADER_STEP_DURATION,
    VITE_AURA_PROJECT_ID: import.meta.env.VITE_AURA_PROJECT_ID,
    VITE_AURA_CARRIER_CODE: import.meta.env.VITE_AURA_CARRIER_CODE,
    VITE_AURA_DE_URL: import.meta.env.VITE_AURA_DE_URL,
    VITE_MARKETING_PATH: import.meta.env.VITE_MARKETING_PATH,
    VITE_JWT_PATH: import.meta.env.VITE_JWT_PATH,
    VITE_YOUSIGN_SIGNATURE_UI_ID: import.meta.env.VITE_YOUSIGN_SIGNATURE_UI_ID,
    VITE_AURA_INTERVIEW_ID: import.meta.env.VITE_AURA_INTERVIEW_ID,
    VITE_TIME_LOADER: import.meta.env.VITE_TIME_LOADER,
    VITE_YOUSIGN_URL: import.meta.env.VITE_YOUSIGN_URL,
    VITE_UNLEASH_APP_KEY: import.meta.env.VITE_UNLEASH_APP_KEY,
    VITE_UNLEASH_ENV: import.meta.env.VITE_UNLEASH_ENV,
    VITE_UNLEASH_URL: import.meta.env.VITE_UNLEASH_URL,
    VITE_QONTO_CLIENT_ID: import.meta.env.VITE_QONTO_CLIENT_ID,
    VITE_QONTO_OAUTH_URL: import.meta.env.VITE_QONTO_OAUTH_URL,
    VITE_GOOGLE_CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    VITE_GOOGLE_OAUTH_URL: import.meta.env.VITE_GOOGLE_OAUTH_URL,
    VITE_CAARL_CHATBOT_URL: import.meta.env.VITE_CAARL_CHATBOT_URL,
    VITE_CAARL_CHATBOT_API_KEY: import.meta.env.VITE_CAARL_CHATBOT_API_KEY,
    VITE_N8N_EVENT_TRACKING_PATH: import.meta.env.VITE_N8N_EVENT_TRACKING_PATH,
    VITE_DOCUMENT_CLAIM_TRANSFER_PROPERTY_PATH: import.meta.env.VITE_DOCUMENT_CLAIM_TRANSFER_PROPERTY_PATH,
    VITE_DOCUMENT_CLAIM_PROOF_PATH: import.meta.env.VITE_DOCUMENT_CLAIM_PROOF_PATH,
  };

  Object.keys(envVar).forEach((key) => {
    if (envVar[key as EnvVars] === undefined || envVar[key as EnvVars] === null) {
      delete envVar[key as EnvVars];
    }
  });

  return envVar;
};

export const OVERLOADED_ENV_VAR = getOverloadedEnvVar();

export const dynamicsEnvsVars = (): Partial<Record<EnvVars, string>> => {
  const env = getEnv();
  const dynamicEnvs: Record<string, string> = {};
  if (env === 'feature' || env === 'preproduction' || env === 'demo' || env === 'prod') {
    const mainDomain: string = getMainDomain();
    dynamicEnvs['VITE_COOKIE_DOMAIN'] = '.' + mainDomain;
    dynamicEnvs['VITE_API_ENDPOINT'] = generateSubdomainUrl('api');
    dynamicEnvs['VITE_STATIC_ENDPOINT'] = generateSubdomainUrl('static');
    dynamicEnvs['VITE_EXTRANET_HOST'] = 'https://extranet.' + mainDomain;
    dynamicEnvs['VITE_APP_HOST'] = generateSubdomainUrl('app');
    dynamicEnvs['VITE_AUTH_HOST'] = generateSubdomainUrl('auth');
    dynamicEnvs['VITE_AXEPTIO_DOMAIN'] = '.' + mainDomain;
    dynamicEnvs['VITE_QUOTE_HOST'] = generateSubdomainUrl('quote', getPartner()?.slug);
  }
  return dynamicEnvs;
};

export const generateSubdomainUrl = (
  subdomain: 'app' | 'extranet' | 'quote' | 'auth' | 'static' | 'api',
  partner?: string,
): string =>
  partner ? `https://${partner}.${subdomain}.${getMainDomain()}` : `https://${subdomain}.${getMainDomain()}`;

export function getEnvVar(envVar: EnvVars): string | undefined {
  const dynamicEnvs = dynamicsEnvsVars();
  const env = getEnv();

  return (
    OVERLOADED_ENV_VAR?.[envVar] ??
    {
      ...ENVS['default'],
      ...ENVS[env]?.['default'],
      ...dynamicEnvs,
    }?.[envVar]
  );
}

const ENVS: Partial<EnvVarsType> = {
  default: {
    VITE_APP_HUB_PATH: '/',
    VITE_APP_MATCHING_PATH: '/matching',
    VITE_APP_REVENUE_LOSS_PATH: '/revenue-loss',
    VITE_APP_HEALTHCARE_PATH: '/healthcare',
    VITE_APP_COACH_PATH: '/coach',
    VITE_JWT_PATH: 'jwt_token',
    VITE_ITEMS_PER_PAGE: '10',
    VITE_MSB_DASHBOARD_LETTER_ENDPOINT: 'https://www.mysendingbox.fr/app/dashboard/letters/',
    VITE_HUBSPOT_ENDPOINT: 'https://app.hubspot.com',
    VITE_MARKETING_PATH: 'https://www.stello.eu',
    VITE_AXEPTIO_ID: '5f97f8f91912c95265bbf2eb',
    VITE_AXEPTIO_DOMAIN: 'local.stello.dev',
    VITE_AXEPTIO_VERSION: undefined,
    VITE_AURA_DE_URL: 'https://coreuk-prod-disclosures.auraservices.cloud/disclosures',
    VITE_AURA_CARRIER_CODE: 'EasyBlue01',
    VITE_AURA_PROJECT_ID: '168',
    VITE_AURA_INTERVIEW_ID: '658',
    VITE_STRIPE_API_KEY: 'pk_test_09gt8393qv2r2rj6AKkbNQF5',
    VITE_SWITCH_USER_PATH: 'switch_user',
    VITE_LOADER_STEP_DURATION: '200',
    VITE_HUBSPOT_MEETING_PATH: 'https://meetings.hubspot.com/lmaurice/easyblue-vous-rappelle',
    VITE_TERMS_SERVICE_PATH: 'https://www.stello.eu/conditions-generales-dutilisation',
    VITE_PRIVACY_POLICE_PATH: 'https://www.stello.eu/charte-de-confidentialite',
    VITE_LEGAL_NOTICE_PATH: 'https://www.stello.eu/mentions-legales',
    VITE_COMPLAINTS_PATH: 'https://www.stello.eu/reclamations',
    VITE_FAQ_PATH: 'https://help.stello.eu/',
    VITE_YOUSIGN_URL: 'https://staging-app.yousign.com/procedure/sign?members=',
    VITE_YOUSIGN_SIGNATURE_UI_ID: '/signature_uis/a5261fc8-2c27-493f-b04b-75366eeda290',
    VITE_TIME_LOADER: '3000',
    VITE_TITLE_APP: 'Stello, assureur 100% digital pour les entrepreneurs',
    VITE_TITLE_AUTH: 'Stello, assureur 100% digital pour les entrepreneurs',
    VITE_TITLE_QUOTE: 'Stello, assureur 100% digital pour les entrepreneurs',
    VITE_TITLE_EXTRANET: 'Stello - Extranet',
    VITE_AS_CUSTOMER_PATH: 'app_as_customer',
    VITE_DESCRIPTION:
      'Stello, la solution digitale pour gérer et assurer de manière proactive les risques des entrepreneurs.' +
      'Stello vous protège de manière simple, transparente et rapide dans votre quotidien professionnel.',
    VITE_ENV: undefined,
    VITE_API_ENDPOINT: undefined,
    VITE_STATIC_ENDPOINT: undefined,
    VITE_APP_HOST: undefined,
    VITE_EXTRANET_HOST: undefined,
    VITE_QUOTE_HOST: undefined,
    VITE_SENTRY_DSN_APP: undefined,
    VITE_SENTRY_DSN_AUTH: undefined,
    VITE_SENTRY_DSN_QUOTE: undefined,
    VITE_SENTRY_DSN_EXTRANET: undefined,
    VITE_COOKIE_DOMAIN: undefined,
    VITE_API_PATH: '/v1',
    VITE_BASE_NAME: '/',
    VITE_DO_NOT_TRACK_PATH: 'DNT',
    VITE_GTM_APP: undefined,
    VITE_GTM_EXTRANET: undefined,
    VITE_HUBSPOT_PORTAL: undefined,
    VITE_UNLEASH_URL: 'https://unleash.external.stello.eu/api/frontend',
    VITE_UNLEASH_ENV: 'development',
    VITE_UNLEASH_APP_KEY: undefined,
    VITE_CAARL_CHATBOT_URL: 'https://api.staging.caarl.fr/v1/chatbot/stello/',
    VITE_CAARL_CHATBOT_API_KEY: '1u8XtQrOGhwsATakbaBdeaMAE9MnB2HydRETXQ7lZkWJP5jD9pOMCs3IteqNHHVO',
    VITE_QONTO_CLIENT_ID: 'a3eacdf3-ef6b-470e-88dd-e3f19358d207',
    VITE_QONTO_OAUTH_URL: 'https://oauth-sandbox.staging.qonto.co/oauth2/auth',
    VITE_GOOGLE_OAUTH_URL: 'https://accounts.google.com/o/oauth2/v2/auth',
    VITE_GOOGLE_CLIENT_ID: '1072478111184-5ud77r0jna71boch42o85g23j10pocrl.apps.googleusercontent.com',
    VITE_N8N_EVENT_TRACKING_PATH: 'https://n8n.external.stello.dev/webhook/76a34c20-a27f-4500-ac33-86f452aa8833',
    VITE_DOCUMENT_CLAIM_TRANSFER_PROPERTY_PATH:
      'https://storage.googleapis.com/stello-assets/templates/Formulaire%20de%20transfert%20de%20biens%2029-05-24.pdf',
    VITE_DOCUMENT_CLAIM_PROOF_PATH:
      'https://storage.googleapis.com/stello-assets/templates/Justificatif%20d%E2%80%99entre%CC%81e%20en%20immobilisation%2023-05-2024.pdf',
  },
  dev: {
    default: {
      VITE_ENV: 'dev',
      VITE_API_ENDPOINT: 'http://localhost:8080',
      VITE_STATIC_ENDPOINT: 'http://localhost:8080',
      VITE_APP_HOST: 'https://app.local.stello.dev',
      VITE_EXTRANET_HOST: 'https://extranet.local.stello.dev',
      VITE_QUOTE_HOST: 'https://quote.local.stello.dev',
      VITE_AUTH_HOST: 'https://auth.local.stello.dev',
      VITE_SENTRY_DSN_APP: undefined,
      VITE_SENTRY_DSN_AUTH: undefined,
      VITE_SENTRY_DSN_QUOTE: undefined,
      VITE_SENTRY_DSN_EXTRANET: undefined,
      VITE_COOKIE_DOMAIN: '.local.stello.dev',
      VITE_GTM_APP: undefined,
      VITE_GTM_EXTRANET: undefined,
      VITE_HUBSPOT_PORTAL: '21621130',
      VITE_AXEPTIO_ID: undefined,
    },
  },
  feature: {
    default: {
      VITE_ENV: 'feature',
      VITE_HUBSPOT_PORTAL: '24226331',
      VITE_UNLEASH_APP_KEY: 'default:development.eea384d75c14c240d9672feec32cc17bc7b652ac1820874764f27b5a',
    },
  },
  preproduction: {
    default: {
      VITE_ENV: 'preproduction',
      VITE_HUBSPOT_PORTAL: '24226331',
      VITE_UNLEASH_APP_KEY: 'default:development.eea384d75c14c240d9672feec32cc17bc7b652ac1820874764f27b5a',
    },
  },
  demo: {
    default: {
      VITE_ENV: 'demo',
      VITE_HUBSPOT_PORTAL: '24226331',
      VITE_UNLEASH_APP_KEY: 'default:development.eea384d75c14c240d9672feec32cc17bc7b652ac1820874764f27b5a',
    },
  },
  prod: {
    default: {
      VITE_ENV: 'prod',
      VITE_HUBSPOT_PORTAL: '5233122',
      VITE_STRIPE_API_KEY: 'pk_live_pQgbUM5WTMBsl9hxJY6LpJFW',
      VITE_YOUSIGN_URL: 'https://prod-app.yousign.com/procedure/sign?members=',
      VITE_YOUSIGN_SIGNATURE_UI_ID: '/signature_uis/13b423bb-ee1e-4590-beee-f292c9554b86',
      VITE_SENTRY_DSN_APP: 'https://5db27a62152748dc9e0857ff3cbe369b@o275328.ingest.sentry.io/5579965',
      VITE_SENTRY_DSN_AUTH: 'https://446c27b32839c0b8a4f5ef1ae07493b0@o275328.ingest.sentry.io/4506830993358848',
      VITE_SENTRY_DSN_QUOTE: 'https://ca9ffbf7c7905001f041e78fb0906e1a@o275328.ingest.sentry.io/4506111241158656',
      VITE_SENTRY_DSN_EXTRANET: 'https://5d65e1bfad174368bf09cc5ad5c4e0e3@o275328.ingest.sentry.io/5660017',
      VITE_LOADER_STEP_DURATION: '500',
      VITE_GTM_APP: 'GTM-5KZC7KM',
      VITE_GTM_EXTRANET: 'GTM-MS3M8JD',
      VITE_AXEPTIO_VERSION: 'easyblue-base',
      VITE_AURA_DE_URL: 'https://coreuk-prodsrv-disclosures.auraservices.cloud/disclosures',
      VITE_UNLEASH_APP_KEY: 'default:production.71391d33e6c44ed8556e7949bc18c2f08edbef021b5d1d322f7e1bd6',
      VITE_UNLEASH_ENV: 'production',
      VITE_CAARL_CHATBOT_URL: 'https://api.caarl.fr/v1/chatbot/stello/',
      VITE_QONTO_CLIENT_ID: '2af4b2b9-da7c-440e-b74e-f67e77dd9e18',
      VITE_QONTO_OAUTH_URL: 'https://oauth.qonto.com/oauth2/auth',
      VITE_N8N_EVENT_TRACKING_PATH: 'https://n8n.external.stello.eu/webhook/76a34c20-a27f-4500-ac33-86f452aa8833',
      VITE_GOOGLE_CLIENT_ID: '81073923913-onefm5q2hguc3pp8inmjjctm9l2hmcsh.apps.googleusercontent.com',
    },
  },
  test: {
    default: {
      VITE_COOKIE_DOMAIN: 'localhost',
    },
  },
};

const FEATURES: EnvFeaturesType = {
  dev: {},
  feature: {},
  preproduction: {},
  demo: {},
  prod: {},
  default: {},
  test: {},
};

export const featureEnabled = (name: FeatureName): boolean =>
  (({
    ...FEATURES['default'],
    ...FEATURES[getEnv()]['default'],
  })?.[name] || '0') === '1';
