import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import smoothscroll from 'smoothscroll-polyfill';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css';

import { getEnvVar } from '#shared/envs';
import { injectAxeptio } from '#shared/utils/axeptio';
import { injectLink, injectMeta } from '#shared/utils/head';
import { injectScript as hubspotInjectScript } from '#shared/utils/hubspot';
import { injectGTM, injectGTMNoScript } from '#shared/utils/tagmanager';

import Router from '#app/Router';

SwiperCore.use([Navigation, Autoplay]);

const App: React.FC = () => {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      <Helmet>
        {injectLink()}
        <title>{getEnvVar('VITE_TITLE_APP')}</title>
        {injectMeta()}
        {hubspotInjectScript()}
        {injectGTM()}
        {!Cookies.get(getEnvVar('VITE_DO_NOT_TRACK_PATH') || 'DNT') && injectAxeptio()}
      </Helmet>
      {injectGTMNoScript()}
      <Router />
    </>
  );
};

export default App;
